
import shape44 from '../../assets/images/shape/shape_44.svg';
import shape45 from '../../assets/images/shape/shape_45.svg';

export default function FooterImages() {
    return (
        <>
        <img src={shape44} alt="" className="shapes shape-one"/>
        <img src={shape45} alt="" className="shapes shape-two"/>
        </>
    )
}