
import shape38 from '../../assets/images/shape/shape_38.svg';
import shape39 from '../../assets/images/shape/shape_39.svg';

export default function BannerImages() {
    return (
        <>
        <img src={shape38} alt="" className="shapes shape-one"/>
        <img src={shape39} alt="" className="shapes shape-two"/>
        </>
    )
}